import "./App.css";
import PDF from "./WG_Resume_2023.pdf";
// import WG from "./"
export default function MyApp() {
  return (
    <div className="App">
      {/* <div>
        <a href={PDF} target="_blank" rel="noopener noreferrer">
          resume
        </a>
      </div> */}

      <div>
        <a
          href="https://twitter.com/willjguo"
          target="_blank"
          rel="noopener noreferrer"
        >
          twitter
        </a>
      </div>

      <div>
        <a
          href="https://www.instagram.com/willjguo/"
          target="_blank"
          rel="noopener noreferrer"
        >
          instagram
        </a>
      </div>
    </div>
  );
}